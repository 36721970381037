<template>
  <div>
    <div class="d-flex flex-column px-4 py-4">
      <h2 class="mb-4 cursive-font">Support Messages</h2>

      <v-data-table :headers="headers" :items="clients" item-key="id" class="elevation-1" fixed-header
        :custom-filter="filterOnlyCapsText" :page.sync="page" :loading="loading" :server-items-length="totalItems"
        :items-per-page.sync="itemsPerPage" @update:page="handlePageChange" loading-text="Loading... Please wait">

        <template v-slot:item.created_at="{ item }">
          <span>{{ new Date(item.created_at).toLocaleString() }}</span>
        </template>

        <template v-slot:item.delete="{ item }">
          <v-btn small text color="primary" @click="deleteSupportMessage(item.id)">
            Delete
          </v-btn>
        </template>

      </v-data-table>

    </div>
    <v-alert :type="isError ? 'error' : 'success'" class="popup-message" v-if="isVisible">
      {{ message }}
    </v-alert>
  </div>
</template>

<script>

import TableWithFilters from "@/components/common/TableWithFilters.vue";
import { computed, onMounted, ref } from "@vue/composition-api";
import { VTextField, VCheckbox } from "vuetify/lib";
import axios from "@axios";
export default {
  setup() {
    const loading = ref(false)
    const switchloading = ref(false)
    const page = ref(1)
    const itemsPerPage = ref(0)
    const totalItems = ref(0)
    const headers = computed(() => [
      {
        text: 'TimeStamp',
        value: 'created_at',
        width: 200
      },
      {
        text: 'Email',
        value: 'email_id',
        width: 250
      },
      {
        text: 'Full Name',
        value: 'user_name',
      },
      {
        text: 'Subject',
        value: 'subject'
      },
      {
        text: 'Message',
        value: 'message_desc',
        width: 300
      },
      {
        text: '',
        value: 'delete',
        sortable: false
      },
    ])
    const clients = ref([])
    const isVisible = ref(false)
    const isError = ref(false)
    const message = ref(null)

    async function fetchTableData() {
      try {
        loading.value = true;
        const { data } = await axios.get('/support',
        );

        clients.value = data
        page.value = parseInt(data.page);
        itemsPerPage.value = data.itemsPerPage;
        totalItems.value = data.total;

      } catch (err) {
        console.error('Error : ', err);
        throw err
      } finally {
        loading.value = false;
      }
    }

    async function deleteSupportMessage(id) {
      try {
        axios.delete(`/support/${id}`)
        setAlertData('Message Deleted Successfully', false, true, 2500)
        clients.value = clients.value.filter(user => user.id != id)
      } catch (err) {
        setAlertData('Message Not Deleted', true, true, 2500)
        console.log('Error : ', err)
        throw err
      }
    }

    const setAlertData = (msg = 'Hello', err, show, duration) => {
      message.value = msg
      isError.value = err
      isVisible.value = show

      setTimeout(() => {
        message.value = null
        isError.value = false
        isVisible.value = false
      }, duration)
    }


    onMounted(() => {
      fetchTableData();
    });

    const filterOnlyCapsText = (value, search, item) => {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLowerCase().indexOf(search) !== -1
    }

    function handlePageChange(event) {
      page.value = parseInt(event);
      fetchTableData();
    }

    return {
      itemsPerPage,
      totalItems,
      page,
      handlePageChange,
      switchloading,
      loading,
      headers,
      clients,
      TableWithFilters,
      VTextField,
      VCheckbox,
      filterOnlyCapsText,
      deleteSupportMessage,
      isVisible,
      isError,
      message
    }
  }
}
</script>

<style scoped>
thead.v-data-table-header th[role=columnheader] {
  font-size: 18px !important;
}

.popup-message {
  position: fixed;
  right: 10px;
  top: 75px;
  width: 300px !important;
  z-index: 999;
  padding: 0.8rem;
  border-radius: 10px;
  font-weight: 500;
}
</style>